import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import Intensivutbildning2veckorImg from '../../images/utbildningar/intensivutbildning_2_veckor.jpg'

const IntensivkursTvaveckor = () => (
  <Layout>
    <Seo title='Intensivkurs i 2 veckor' />
    <PageHeader text='Intensivkurs i 2 veckor' />
    <div className='container'>
      <div className='row justify-content-around'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mb-3'>
          <div>
            <h2>Utbildningsinnehåll & Bokning</h2>
            <p>
              Intensivkurs i 2 vecko hos oss innebär att du kan få köra 2-6 gånger om dagen.
              Lektionstiden varierar från 50 till 150 min per tillfälle.
              Teori pluggas individuellt under utbildningstiden.
            </p>
            <p>
              2 veckor är väldigt kort tid för att lära sig allt.
              Har du inte haft möjligheten att utbilda dig innan du kommer till kursstart,
              finns det en stor sannolikhet att du inte kommer kunna tillgodogöra dig hela utbildningen på den korta tiden.
              Vi förbehåller oss rätten att flytta sammanhållet förarprov om utbildningsplanernas mål inte uppnås under kursens gång.
            </p>

            <h4>2 veckors intensivkurs innehåller:</h4>
            <ul>
              <li className='mb-0'>30 st. körlektioner á 50 min.</li>
              <li className='mb-0'>Komplett digitalt utbildningsmaterial och tillgång till elevcentralens teorifrågor.  </li>
              <li className='mb-0'>Riskutbildning 1 (alkohol/droger, trötthet, riskbeteenden).</li>
              <li className='mb-0'>Riskutbildning 2 (säkerhet och körning under särskilda förhållanden)</li>
              <li className='mb-0'>Vi ordnar tid för kunskapsprov (teoriprov)</li>
              <li className='mb-0'>Lån av trafikskolans personbil vid förarprov hos trafikverket. (uppkörning)</li>
            </ul>
          </div>

          <div>
            <h3>Anmälan & Betalning</h3>
            <p>
              Du anmäler dig genom att ta kontakt med oss.
              Anmälan ska ske minst 4 veckor innan.
              Glöm inte att du måste ha ett godkänt körkortstillstånd innan du anmäler dig.
            </p>
            <p>
              Intensivkursen måste vara betald innan kursstart och kostanden är 26 340 kr.
              Utöver detta så tillkommer trafikverkets och transportstyrelsens avgifter.
              Du kan betala med kort, faktura (20 dagar), kontant eller presentkort.
            </p>
          </div>

          <div>
            <h3>Avbokning och återbetalning</h3>
            <p>
              Avbokning av anmäld intensivkurs måste ske minst 1 vecka innan kursstarten.
              Sker avbokningen senare debiteras du för eventuella administrationskostnader.
              Vi återbetalar självklart dig om du eventuellt inte har kunnat utnyttja alla lektionerna.
              I händelse av sjukdom under utbildningen försöker vi ordna på ett sådant sätt att intensivkursen kan återupptas när det är möjligt.
            </p>
          </div>

          <div>
            <h3>Andra upplägg</h3>
            <p>
              Ett annat upplägg med lektioner utspridda under en lite längre period kanske passar dig bättre om du inte har möjlighet att gå en intensivkurs.
              Vi kan då planera upp en mera individuell intensivutbildning utifrån dina förutsättningar och din tillgänglighet.
            </p>
          </div>

        </div>

        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3 text-center'>
          <img src={Intensivutbildning2veckorImg} alt='intensivutbildning 2 veckor' className='img-fluid rounded' />
          <p className='mb-1 text-sm-center'>
            <Link to='/kontakta-oss/utbildningsanmalan' className='btn btn-danger'> Gör din anmälan här</Link>
          </p>
        </div>

      </div>
    </div>
  </Layout>
)

export default IntensivkursTvaveckor
